.carousel-bg {
	background: var(--color-carusal);
	border-radius: 45px;

}

.carousel-box {
}

.speech-bubble {
	position: relative;
	border: 1px solid var(--color-highlight);
	border-radius: .4em;
	max-width: 600px;
	margin: 0 auto;
	padding: 15px;

}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 20%;
	width: 0;
	height: 0;
	border: 36px solid transparent;
	border-top-color: var(--color-highlight);
	border-bottom: 0;
	border-right: 0;
	margin-left: -18px;
	margin-bottom: -36px;
}

.carousel-name {
	color: var(--color-highlight);
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	text-align: center;
	margin-top: 1.5rem;
}

.carousel-position {
	font-weight: 400;
	font-size: 18px;
	line-height: 23px;
	color: #FFFFFF;
	margin-bottom: 1rem;
}

.carousel-indicators [data-bs-target] {
	color: black;
}


.carousel-indicators .active {
	color: black;
}

.carousel-control-next,
.carousel-control-prev {
	align-items: end;
	padding: 1rem;
}

@media(max-width: 768px) {
	.speech-bubble {
		padding: 15px;
		font-size: 14px;
	}

	.carousel-caption {
		position: relative;
		max-width: 74%;
	}

}