.navbar{
    padding: 40px 0px;
    background-color: var(--color-highlight);
}
.nav-link {
    color: var(--color-text);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: .4rem;
   
}

.nav-link:last-child {
    border: 1px solid;
    border-radius: 5px;
    margin-left: 10px;
}

.nav-link:hover {
    background: var(--color-highlight);
    border-radius: 5px;
    color: var(--color-text);
}

.nav-link:last-child:hover {
    background: var(--color-highlight);
    color: var(--color-text);

}

@media(max-width: 991px) {

    .navbar-collapse,
    .navbar-nav {
        text-align: right;
    }

    .nav-link {
        margin: .3rem 0;
        padding: .5rem;
    }

    .nav-link:last-child {
        background: var(--color-highlight);
        color: var(--color-text);
        text-align: center;


    }
}

.navbar-toggler{
    border: none;
    padding-top: .8rem;
    text-align: center;
    margin-bottom: 1rem;
}
.navbar-toggler-icon{
    background: url(../../assets/menu.png) no-repeat;
    
}
.nav-item {
    position: relative;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .submenu {
    display: none;
    position: absolute;
    background: #680019;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1000;
    top: 100%; /* Align submenu right below the main menu item */
    left: 0; /* Align submenu with the left edge of the main menu item */
  }
  
  .nav-item:hover .submenu {
    display: block;
  }
  
  .submenu .nav-link {
    display: block;
    padding: 10px;
    color: whitesmoke;
    text-decoration: none;
    white-space: nowrap; /* Ensure text doesn't wrap to the next line */
  }
  
  .submenu .nav-link:hover {
    background: #570014; /* Change background color on hover for submenu items */
  }