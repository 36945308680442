.clients{
   display: flex;
   justify-content: center;
   flex-wrap: nowrap;
   overflow-x: scroll;
  }
.clients img{
    filter: grayscale(100%);
    cursor: pointer;
    padding: .5rem;
    margin: 1rem 1rem 0rem;
}

.clients img:hover{
    filter: grayscale(0);
}
::-webkit-scrollbar {
    width: 0;  
    background: transparent;  
}

